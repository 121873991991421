//  =========================================================================================
// File Name: main-menu-mixin.scss
// Description: Common mixin for menus, contain dark and light version scss.
// ----------------------------------------------------------------------------------------
// Item Name: Frest HTML Admin Template
// Version: 1.0
// Author: PIXINVENT
// Author URL: http://www.themeforest.net/user/pixinvent
//==========================================================================================

// Header navbar content and brand expanded & collapsed stats
// spacing and sizing.
// ================================================

// Mixins for the menu and navbar position, margin and width change
@mixin main-menu-width($menu-width) {
  margin-left: $menu-width;
}
@mixin menu-navbar-width($menu-width) {
  float: left;
  width: $menu-width;
}
// Mixins for the menu flipped menu
@mixin vertical-flipped-menu($menu-width) {
  margin: 0;
  margin-right: $menu-width;
}
// Mixins for the sub menu arrow
@mixin vertical-menu-has-sub-arrow($arrow-font-size) {
  &.has-sub {
    > a:not(.mm-next):after {
      content: "\ea4f";
      font-family: "boxicons";
      font-size: $arrow-font-size;
      color: #8494a7;
      display: inline-block;
      position: absolute;
      right: 7px;
      transform: rotate(0deg);
      transition: -webkit-transform 0.4s ease-in-out;
    }
  }
  &.open {
    > a:not(.mm-next):after {
      transform: rotate(180deg);
    }
  }
}

// Light & Dark menu mixin
// $theme-color : Menu text color
// $theme-bg    : Menu background color
// ================================================
@mixin menu-theme($theme-color, $theme-bg, $theme-border, $main-menu-sub-bg, $main-menu-active-bg) {
  $main-menu-top-hover-color: darken($theme-color, 10%);

  $main-menu-top-open-hover-bg: darken($theme-bg, 6%);
  $main-menu-top-open-head-bg: darken($theme-bg, 8%);

  $main-menu-active-bg: $main-menu-active-bg;

  $main-menu-top-active-bg: darken($theme-bg, 4%); //$primary
  $main-menu-top-active-color: $white;
  $main-menu-top-active-head-bg: darken($theme-bg, 4%); //$primary

  $main-menu-sub-bg: $main-menu-sub-bg;
  $main-menu-sub-color: $theme-color;
  $main-menu-sub-hover-bg: darken($theme-bg, 4%);
  $main-menu-sub-hover-color: lighten($theme-color, 4%);
  $main-menu-sub-active-bg: rgba($black, 0.06);

  $main-menu-sub-open-bg: darken($theme-bg, 4%);
  $main-menu-sub-open-color: $theme-color;

  color: $theme-color;
  background: $theme-bg;
  border: $theme-border;

  .navigation {
    background: $theme-bg;
    .navigation-header {
      color: #bac0c7;
      margin: calc(#{$content-padding} - 0.5rem) 0 0.5rem 1.8rem;
      padding: 0;
      letter-spacing: 1px;
    }
    li {
      &.has-sub {
        ul {
          padding: 7px 0 0;
          margin: -7px 0 0;
          li {
            &.has-sub {
              ul.menu-content {
                > li {
                  a {
                    padding: $menu-third-level-padding !important;
                    transition: all 0.35s ease !important;
                  }
                  a {
                    &:hover {
                      padding-left: 25px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      a {
        display: flex;
        align-items: center;
        color: #8494a7;
        padding: 10px 12px;
      }
    }

    > li {
      margin: 0 1rem;
      transition: background-color 0.5s ease;
      &.nav-item {
        &:not(.has-sub) {
          a {
            padding: 10px 12px;
          }
        }
      }
      &.open {
        &.sidebar-group-active {
          > a {
            padding: 10px 15px;
          }
        }
      }
      &.nav-item {
        &.open,
        &.sidebar-group-active {
          > a {
            margin: 0 11px 0;
            padding: 9px 0;
            transition: transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s;
            i {
              color: $primary !important;
            }
          }
          &.has-sub {
            &.open,
            &.sidebar-group-active {
              border-radius: $border-radius;
              border: 1px solid $theme-border;
              background-color: $main-menu-sub-bg;
              transition: none;
            }
            > a:not(.mm-next):after {
              right: 7px !important;
            }
          }
          .menu-content {
            li {
              a {
                padding: 10px 18px;
              }
              > a {
                &:hover {
                  padding-left: 15px !important;
                }
              }
            }
          }
        }
      }
      &:not(.open) {
        > ul {
          display: none;
        }
      }
      // menu-item active
      &.active {
        &:not(.sidebar-group-active){
          > a {
            // background: $main-menu-active-bg;
            background: rgba($primary, .15);
            color: $primary;
            border-radius: $border-radius;
          }
        }
      }
      // Sub-menu item active
      .active {
        > a {
          margin-bottom: 0;
        }

        .hover{
          background: $main-menu-top-active-head-bg;
        }
      }

      ul {

        li {
          > a {
            padding: $menu-second-level-padding !important;
            margin: 0 11px;
          }
        }
        .has-sub {
          &:not(.open) {
            > ul {
              display: none;
            }
          }
        }
        .open,
        .sidebar-group-active {
          > a {
            color: $main-menu-sub-open-color;
          }
          //for 2rd level menu
          > ul {
            display: block;
            //for 3rd level menu
            .open {
              > ul {
                display: block;
              }
            }
          }
          &.active {
            background-color: inherit;
          }

        }

        // Active Sub-menu
        .active {
          // background: $main-menu-active-bg;
          background: rgba($primary, .15);
          > a {
            color: $primary;
          }
        }
      }

      > ul {
        > li {
          &:first-child > a {
            border-top: 1px solid $theme-border;
          }
          &.active {
            &:first-child > a {
              border-top: none;
            }
          }
        }
      }
    }
  }
}
// Menucontent display mixin
// $menu-expanded-width : Expanded menu width
// $menu-collapsed-width: Collapsed menu width
// ================================================
@mixin menu-content($menu-expanded-width, $menu-collapsed-width, $theme-color, $theme-bg) {
  .main-menu-content {
    > span.menu-title,
    a.menu-title {
      right: -($menu-expanded-width);
      width: $menu-expanded-width;
      font-weight: 600;
      color: $white;
      text-transform: uppercase;
      text-align: left;
      background-color: $primary;
      border-color: $primary;
      padding: 13px 20px;
    }
    > ul.menu-content {
      left: $menu-collapsed-width;
      width: $menu-expanded-width;
      box-shadow: 1px 0px 2px rgba($black, 0.1);
      border-bottom: 2px solid rgba($black, 0.2);
      border-left: 1px solid rgba($black, 0.02);

      li {
        white-space: nowrap;
        position: relative;

        a {
          display: block;
          padding: 8px 20px 8px 20px;
        }

        @include vertical-menu-has-sub-arrow(1.2rem);
      }
    }
  }
}
