/*=========================================================================================
    File Name: vertical-menu-modern.scss
    Description: A calssic vertical modern style menu with expand and collops support. It support
    light & dark version, filpped layout, right side icons, native scroll and borders menu
    item seperation.
    ----------------------------------------------------------------------------------------
    Item Name: Frest HTML Admin Template
    Version: 1.0
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
body.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a > i {
  width: 1.6rem !important;
  margin-right: 1rem;
  float: left;
}

body.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a > i:not(.menu-livicon) {
  font-size: 1.4rem;
}

body.vertical-layout.vertical-menu-modern .main-menu .navigation .menu-content > li > a {
  transition: all .35s ease !important;
}

body.vertical-layout.vertical-menu-modern .main-menu .navigation .menu-content > li > a > i {
  margin-right: 1.25rem;
}

body.vertical-layout.vertical-menu-modern .main-menu {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
  width: 260px;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation > li > a > span.badge {
  position: absolute;
  right: 7px;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation > li.open > a > span.badge {
  right: 19px;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub > a:not(.mm-next):after {
  content: "\ea4f";
  font-family: "boxicons";
  font-size: 1.2rem;
  color: #8494a7;
  display: inline-block;
  position: absolute;
  right: 7px;
  transform: rotate(0deg);
  transition: -webkit-transform 0.4s ease-in-out;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.open > a:not(.mm-next):after {
  transform: rotate(180deg);
}

body.vertical-layout.vertical-menu-modern.menu-expanded .footer {
  margin-left: 260px;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .navbar.fixed-top {
  left: 78px;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu {
  width: 78px;
  transition: 300ms ease all;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navbar-header .brand-text,
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .modern-nav-toggle {
  display: none;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation-header {
  margin: 0;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li a {
  display: block;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li a .menu-title, body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li a .badge {
  display: none;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li:last-child {
  margin-bottom: 1.25rem !important;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
  background: rgba(90, 141, 238, 0.2);
  box-shadow: none;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.menu-collapsed-open {
  background: rgba(90, 141, 238, 0.2);
  border: none;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation {
  overflow: visible;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li.navigation-header span {
  display: none;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li > a {
  text-overflow: inherit;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li > a > span.badge {
  float: none !important;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li > a i {
  margin-right: 2rem;
  float: left;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded {
  width: 260px;
  box-shadow: 5px 0 25px 0 rgba(55, 70, 95, 0.3);
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation > li.navigation-header span {
  display: block;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation > li > a i {
  margin-right: 1rem;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation > li > a > span.badge {
  position: absolute;
  right: 20px;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub > a:not(.mm-next):after {
  content: "\ea4f";
  font-family: "boxicons";
  font-size: 1.2rem;
  color: #8494a7;
  display: inline-block;
  position: absolute;
  right: 7px;
  transform: rotate(0deg);
  transition: -webkit-transform 0.4s ease-in-out;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.open > a:not(.mm-next):after {
  transform: rotate(180deg);
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navbar-header .brand-text {
  display: inline;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .modern-nav-toggle {
  display: block;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .navbar-static-top .navbar-wrapper,
body.vertical-layout.vertical-menu-modern.menu-collapsed .app-content,
body.vertical-layout.vertical-menu-modern.menu-collapsed .footer {
  margin-left: 78px;
}

@media (min-width: 992px) {
  body.vertical-layout.vertical-menu-modern .main-menu {
    border-right: 1px solid #DFE3E7;
  }
  body.vertical-layout.vertical-menu-modern .main-menu.menu-dark {
    border-right: 1px solid #464d5c;
  }
  body.vertical-layout.vertical-menu-modern .footer {
    margin-left: 260px;
  }
}

@media (max-width: 1199.98px) {
  body.vertical-layout.vertical-menu-modern .main-menu {
    width: 0;
  }
  body.vertical-layout.vertical-menu-modern .main-menu .navigation li.navigation-header span {
    display: none;
  }
  body.vertical-layout.vertical-menu-modern .content,
  body.vertical-layout.vertical-menu-modern .footer {
    margin-left: 0;
  }
}

@media (max-width: 767.98px) {
  body.vertical-layout.vertical-menu-modern .main-menu {
    width: 0;
  }
  body.vertical-layout.vertical-menu-modern .content,
  body.vertical-layout.vertical-menu-modern .footer {
    margin-left: 0;
  }
}

/*=========================================================================================
    File Name: vertical-overlay-menu.scss
    Description: A overlay style vertical menu with show and hide support. It support
    light & dark version, filpped layout, right side icons, native scroll and borders menu
    item seperation.
    ----------------------------------------------------------------------------------------
    Item Name: Frest HTML Admin Template
    Version: 1.0
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.vertical-overlay-menu .content {
  margin-left: 0;
}

.vertical-overlay-menu .navbar .navbar-header {
  float: left;
  width: 260px;
}

.vertical-overlay-menu .navbar[data-nav="brand-center"] .navbar-header {
  padding: 0;
}

.vertical-overlay-menu .navbar[data-nav="brand-center"] .navbar-header .navbar-brand {
  display: flex;
  align-items: center;
  margin-right: 0;
}

.vertical-overlay-menu .navbar[data-nav="brand-center"] .navbar-header .navbar-brand .brand-logo {
  height: 27px;
  width: 35px;
}

.vertical-overlay-menu .navbar[data-nav="brand-center"] .navbar-header .navbar-brand .brand-logo .logo {
  height: 27px;
  display: flex;
  position: relative;
  left: 6px;
}

.vertical-overlay-menu .navbar[data-nav="brand-center"] .navbar-header .navbar-brand .brand-text {
  color: #FFFFFF;
  padding-left: 1rem;
  letter-spacing: 0.01rem;
  font-size: 1.57rem;
}

.vertical-overlay-menu .main-menu, .vertical-overlay-menu.menu-hide .main-menu {
  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition: width .25s,opacity .25s,transform .25s;
  width: 260px;
  left: -260px;
}

.vertical-overlay-menu .main-menu .navigation > li > a > span.badge {
  position: absolute;
  right: 20px;
}

.vertical-overlay-menu .main-menu .navigation > li > a > i {
  margin-right: 14px;
  float: left;
  width: 1.6rem !important;
}

.vertical-overlay-menu .main-menu .navigation > li ul li > a {
  transition: all .35s ease !important;
}

.vertical-overlay-menu .main-menu .navigation > li ul li > a > i {
  margin-right: 1.25rem;
}

.vertical-overlay-menu .main-menu .navigation > li ul li:hover a {
  padding-left: 35px;
}

.vertical-overlay-menu .main-menu .navigation li.has-sub > a:not(.mm-next):after {
  content: "\ea4f";
  font-family: "boxicons";
  font-size: 1.2rem;
  color: #8494a7;
  display: inline-block;
  position: absolute;
  right: 7px;
  transform: rotate(0deg);
  transition: -webkit-transform 0.4s ease-in-out;
}

.vertical-overlay-menu .main-menu .navigation li.open > a:not(.mm-next):after {
  transform: rotate(180deg);
}

.vertical-overlay-menu.menu-open .main-menu {
  opacity: 1;
  transform: translate3d(260px, 0, 0);
  transition: width .25s,opacity .25s,transform .25s;
  top: 0;
}

@media (max-width: 1199.98px) {
  .horizontal-layout.vertical-overlay-menu .app-content .content-wrapper {
    margin-top: 3.5rem;
  }
}
