/*=========================================================================================
    File Name: vertical-overlay-menu.scss
    Description: A overlay style vertical menu with show and hide support. It support
    light & dark version, filpped layout, right side icons, native scroll and borders menu
    item seperation.
    ----------------------------------------------------------------------------------------
    Item Name: Frest HTML Admin Template
    Version: 1.0
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// Core variables and mixins
@import "../../../bootstrap/functions";
@import "../../../bootstrap/mixins";
@import "../../../bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../variables/variables";
@import "../../../bootstrap/variables";

// Overrides user variable
@import "../../variables/components-variables";

// Import first main menu mixin
@import "../../mixins/main-menu-mixin";

// Vertical Overlay Menu
//=========================
.vertical-overlay-menu {
    //Content expanded
    .content{
        @include main-menu-width(0);
    }
    //Navbar expanded
    .navbar{
        .navbar-header{
            @include menu-navbar-width($menu-expanded-width);
        }
        // Navbar- Brand Center - Logo
        &[data-nav="brand-center"] {
          .navbar-header {
            padding: 0;
            .navbar-brand  {
              display: flex;
              align-items: center;
              margin-right: 0;
              // Brand Logo Scss
              .brand-logo {
                height: 27px;
                width: 35px;
                .logo{
                  height: 27px;
                  display: flex;
                  position: relative;
                  left: 6px;
                }
              }

              // Brand Text Scss
              .brand-text {
                color: $white;
                padding-left: 1rem;
                letter-spacing: 0.01rem;
                font-size: 1.57rem;
              }
            }
          }
        }
    }
    // Main menu expanded
    .main-menu{
        opacity: 0;
        transform: translate3d(0,0,0);
        transition: width .25s,opacity .25s,transform .25s;
        width: $menu-expanded-width;
        left: -($menu-expanded-width);

        .navigation{
            > li{
                > a {
                    > span.badge{
                      position: absolute;
                      right: 20px;
                    }
                    > i {
                      margin-right: 14px;
                      float:left;
                      width: 1.6rem !important; // give important bcz it takes default 60px from it's vendor js
                    }
                }
              ul{
                li{
                  >a{
                    transition: all .35s ease !important;
                    >i{
                      margin-right: 1.25rem;
                    }
                  }
                  &:hover{
                    a{
                      padding-left: 35px;
                    }
                  }
                }
              }
            }
            li{
                @include vertical-menu-has-sub-arrow(1.2rem);
            }
        }
    }
    &.menu-open {
        .main-menu{
            opacity: 1;
            transform: translate3d($menu-expanded-width,0,0);
            transition: width .25s,opacity .25s,transform .25s;
            top: 0;
        }
    }
    &.menu-hide {
        .main-menu{
            @extend  .main-menu;
        }
    }
}
@include media-breakpoint-down(lg) {
  .horizontal-layout{
    &.vertical-overlay-menu {
      .app-content{
        .content-wrapper{
          margin-top: 3.5rem;
        }
      }
    }
  }
}
